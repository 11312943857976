import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Grid, Input } from 'semantic-ui-react';
import axios from 'axios';

const UserForm = () => {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    buttonText: 'Create user',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const resetForm = () => {
    setData({
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      sent: false,
      buttonText: 'Create user',
      err: '',
    });
  };

  const history = useHistory();

  const formSubmit = (e) => {
    e.preventDefault();

    setData({
      ...data,
      buttonText: 'Sending...',
    });

    return axios.post('/api/createUser', data).then((res) => {
      console.log('RESPONSE:', res);
      if (res.status !== 200) {
        setData({
          ...data,
          buttonText: 'Failed to send',
          sent: false,
          err: 'fail',
        });
        return setTimeout(() => {
          resetForm();
        }, 6000);
      }
      const username = res.data.profile.email;
      setData({
        ...data,
        sent: true,
        buttonText: 'User created',
        err: 'success',
      });
      history.push({
        pathname: '/authenticate',
        search: `?username=${encodeURIComponent(username)}`,
        state: {
          username: encodeURIComponent(username),
        },
      });
      return <></>;
    // eslint-disable-next-line no-unused-vars
    }).catch((err) => {
      console.log('_err', err);
      console.log(err);
      setData({
        ...data,
        buttonText: 'Failed to send',
        err,
      });
    });
  };

  return (
    <>
      <Form onSubmit={formSubmit}>
        <Form.Group widths="equal">
          <Form.Field
            required
            label="First Name"
            type="input"
            name="firstName"
            control={Input}
            className="form-field"
            id="first-name"
            placeholder="First name"
            value={data.firstName}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            required
            id="last-name"
            control={Input}
            label="Last name"
            placeholder="Last name"
            name="lastName"
            className="form-field"
            value={data.lastName}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            required
            id="email"
            control={Input}
            label="Email"
            name="email"
            placeholder="email@example.com"
            value={data.email}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Field>
          <div style={{ padding: 20 }}>
            <Grid container columns={2}>
              <div className="form-submit">
                <Button type="submit" primary>{data.buttonText}</Button>
              </div>
            </Grid>
          </div>
        </Form.Field>
      </Form>
    </>
  );
};

export default UserForm;
